.schedule-availablity-sec {
    padding-bottom: 4em;
}

.schedule-availablity-header-sec:before{
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.6);
    position: absolute;
}

.schedule-availablity-header-sec h3{
    color: var(--secondary-color);
    font-size: 1.8em;
    font-weight: 600;
    margin-bottom: 0.5em;
}

.schedule-availablity-header-card p{
    color: var(--secondary-color);
    font-size: 1.4em;
    font-weight: 400;
    max-width: 625px;
    margin-bottom: 0;
}

.schedule-availablity-header-card{
    display: flex;
    align-items: flex-start;
    gap: 1em;
}

.schedule-availability-calander-img-sec {
    padding-top: 3em;
    padding-right: 3em;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .schedule-availability-calander-img {
    width: 100%;
  }
  
  .schedule-availability-select-box {
    /* padding-top: 8.9em; */
  }
  
  .schedule-availability-select-header-sec h3 {
    font-size: 1.6em;
    color: var(--tertiary-color);
    font-weight: 700;
    margin-bottom: 0;
  }
  
  .schedule-availability-select-header-sec p {
    color: rgb(0 0 0 / 50%);
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 0;
    padding: 0.8em 0;
  }
  
  .schedule-availability-select-header-sec h5 {
    font-size: 1.4em;
    line-height: 1.5;
    color: var(--primary-color);
  }
  
  .schedule-availability-select-card {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-top: 2em;
  }
  
  .schedule-availability-select-top-sec {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .schedule-availability-select-top-sec h4 {
    color: var(--primary-color);
    font-size: 1.5em;
    font-weight: 600;
  }
  
  .schedule-availability-select-top-sec input[type="checkbox"] {
    position: relative;
    width: 1.8em;
    height: 1.8em;
    color: #363839;
    border: 1px solid #bdc1c6;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    margin: 2px 0 0 0;
  }
  .schedule-availability-select-top-sec input[type="checkbox"]::before {
    position: absolute;
    content: "";
    display: block;
    top: 0px;
    left: 4px;
    width: 7px;
    height: 12px;
    border-style: solid;
    border-color: #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  .schedule-availability-select-top-sec input[type="checkbox"]:checked {
    color: #fff;
    border-color: var(--primary-color);
    background: var(--primary-color);
  }
  .schedule-availability-select-top-sec input[type="checkbox"]:checked::before {
    opacity: 1;
  }
  /* .schedule-availability-select-top-sec input[type=checkbox]:checked ~ label::before {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
              clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    } */
  
  .schedule-availability-select-top-sec label {
    position: relative;
    cursor: pointer;
    font-size: 1.5em;
    font-weight: 600;
    padding: 0 0.25em 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;
  }
  .schedule-availability-select-top-sec label::before {
    position: absolute;
    content: attr(data-content);
    color: #9c9e9f;
    -webkit-clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
    clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
    text-decoration: line-through;
    text-decoration-thickness: 3px;
    -webkit-text-decoration-color: #363839;
    text-decoration-color: #363839;
    transition: -webkit-clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
      -webkit-clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  
  .schedule-availability-select-all-checkbox {
    display: flex;
    gap: 0.5em;
  }
  
  /* Schedule-availability CSS */
  
  .schedule-availability-select-list label.radio-card {
    cursor: pointer;
    width: 100%;
  }
  .schedule-availability-select-list label.radio-card .card-content-wrapper {
    background: #fff;
    border-radius: 5px;
    /* padding: 15px; */
    /* display: grid; */
    box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
    transition: 200ms linear;
    /* display: flex;
      align-items: center;
      justify-content: space-between; */
    padding: 0 2em;
    gap: 1em;
    width: 100%;
    grid-template-columns: 190px auto;
    display: grid;
    width: 100%;
    place-items: center;
  }
  .schedule-availability-select-list label.radio-card .check-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    border: solid 2px #e3e3e3;
    border-radius: 50%;
    transition: 200ms linear;
    position: relative;
  }
  .schedule-availability-select-list label.radio-card .check-icon:before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center center;
    transform: scale(1.6);
    transition: 200ms linear;
    opacity: 0;
  }
  .schedule-availability-select-list label.radio-card input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  /* .schedule-availability-select-list label.radio-card input[type=checkbox]:checked + .card-content-wrapper {
      box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px var(--primary-color);
    } */
  .schedule-availability-select-list
    label.radio-card
    input[type="checkbox"]:checked
    + .card-content-wrapper
    .check-icon {
    background: linear-gradient(90deg, #2062FD 0%, #BD70EA 100%);
    border-color: var(--primary-color);
    transform: scale(1.2);
  }
  
  .schedule-availability-select-list
    .booked-status
    label.radio-card
    input[type="checkbox"]:checked
    + .card-content-wrapper
    .check-icon {
    background: #ffc05a;
    border-color: #ffc05a;
    transform: scale(1.2);
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center center;
  }
  .schedule-availability-select-list .booked-status label.radio-card .check-icon::before{
    content: unset;
  }
  
  .schedule-availability-select-list
    .booked-status
    label.radio-card
    input[type="checkbox"]:checked
    + .card-content-wrapper
    .schedule-availability-time {
    background: #ffc05a;
    border-color: #ffc05a;
  }
  
  .schedule-availability-select-list
    .not-available-status
    label.radio-card
    input[type="checkbox"]:checked
    + .card-content-wrapper
    .check-icon {
   background-color: var(--primary-color);
    border-color: var(--primary-color);
    background-repeat:no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
    /* background-image: url("/assets/images/fitness/icons/not-avilable.svg"); */
    transform: scale(1.2);
    background-position:center center;
  }
  .schedule-availability-select-list .not-available-status label.radio-card .check-icon::before{
    content: unset;
  }
  
  .schedule-availability-select-list
    .not-available-status
    label.radio-card
    input[type="checkbox"]:checked
    + .card-content-wrapper
    .schedule-availability-time {
    background: linear-gradient(90deg, #2062FD 0%, #BD70EA 100%);
    border-color: var(--primary-color);
  }
  
  .schedule-availability-select-list
    label.radio-card
    input[type="checkbox"]:checked
    + .card-content-wrapper
    .check-icon:before {
    transform: scale(1);
    opacity: 1;
  }
  .schedule-availability-select-list
    label.radio-card
    input[type="checkbox"]:focus
    + .card-content-wrapper
    .check-icon {
    box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
    border-color: var((--primary-color));
  }
  .schedule-availability-select-list label.radio-card .card-content img {
    margin-bottom: 10px;
    width: 100%;
  }
  .schedule-availability-select-list label.radio-card .card-content h4 {
    font-size: 16px;
    letter-spacing: -0.24px;
    text-align: center;
    color: #1f2949;
    margin-bottom: 10px;
  }
  .schedule-availability-select-list label.radio-card .card-content h5 {
    font-size: 14px;
    line-height: 1.4;
    text-align: center;
    color: #686d73;
  }
  
  .schedule-availability-time {
    border: 1.11236px solid rgba(0, 0, 0, 0.1);
    padding: 1em 2em;
    border-radius: 50px;
    display: flex;
    gap: 1em;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    color: #000;
  }
  
  .schedule-availability-time span {
    font-size: 1.3em;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  
  .schedule-availability-status-tick {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }
  
  .schedule-availability-status-tick h4 {
    color: rgb(0 0 0 / 50%);
    font-size: 1.3em;
    font-weight: 700;
    margin-bottom: 0;
  }
  
  .schedule-availability-select-list
    label.radio-card
    input[type="checkbox"]:checked
    + .card-content-wrapper
    .schedule-availability-time {
    background-color: var(--primary-color);
  }
  
  .schedule-availability-select-list
    label.radio-card
    input[type="checkbox"]:checked
    + .card-content-wrapper
    .schedule-availability-time
    span {
    color: var(--secondary-color);
  }
  
  .schedule-availability-sec {
    padding-bottom: 4em;
  }
  
  .schedule-availability-select-middle-sec::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }
  
  /* background of the scrollbar except button or resizer */
  .schedule-availability-select-middle-sec::-webkit-scrollbar-track {
    background-color: #fff;
  }
  
  .schedule-availability-select-middle-sec::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
  }
  
  /* scrollbar itself */
  .schedule-availability-select-middle-sec::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff;
  }
  
  .schedule-availability-select-middle-sec::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4;
  }
  
  /* set button(top and bottom of the scrollbar) */
  .schedule-availability-select-middle-sec::-webkit-scrollbar-button {
    display: none;
  }
  
  .schedule-availability-select-bottom-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2em;
  }
  
  .please-be-available-sec {
    display: flex;
    align-items: center;
    gap: 1em;
  }
  
  .please-be-available-sec h5 span {
    font-size: 1.2em;
    font-weight: 500;
    color: var(--primary-color);
  }
  
  .please-be-available-sec h5 {
    display: flex;
    align-items: center;
    gap: 0.8em;
  }
  

  .schedule-availability-select-btn-sec {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.about-me-btn {
    box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
    border-radius: 5px;
    border: 1px solid transparent!important;
    padding: 8px 30px!important;
    font-size: 1.4em!important;
    color: var(--secondary-color);
    font-weight: 500!important;
    /* background: var(--primary-color)!important; */
    transition: opacity .15s ease,background-color .15s ease,box-shadow .15s ease;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    background-image: linear-gradient( to right, #1361FF, #c771e9, #1361FF, #c771e9 );
    background-size: 300% 100%;
}

.about-me-btn:hover,
.about-me-btn:focus{
    color: var(--secondary-color)!important;
    /* background: var(--primary-color)!important; */
    border: 1px solid transparent!important;
    background-image: linear-gradient( to left, #1361FF, #c771e9, #1361FF, #c771e9 )!important;
}

.schedule-availability-calendar-img{
    width: 100%;
}

.schedule-availability-body-sec{
   padding: 4em 0;
}

.upcoming-live-box{
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    gap: 1em;
    padding-top: 2em;
  }
  
  .upcoming-live-card {
    position: relative;
    margin-top: 1em;
  }
  
  .upcoming-live-card::before {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    border-radius: 10px;
    background: rgba(0,0,0,0.5)
    /* background: linear-gradient(0deg, rgba(0, 0, 0, 0) -5.78%, rgba(0, 0, 0, 0) 36.6%, rgba(0, 0, 0, 0.5) 79.19%); */
  }
  
  .upcoming-live-live-status-sec{
    background-color: #dc3545;
    position: absolute;
    display: inline-flex;
    align-items: center;
    gap: 0.5em;
    border-radius: 5px;
    font-size: 1.3em;
    font-weight: 500;
    color: var(--secondary-color);
    padding: 2px 6px;
    top: 20px;
    left: 20px;
  }
  
  .upcoming-live-live-status-sec .dots{
    width: 0.5em;
    height: 0.5em;
    background: #fff;
    border-radius: 50%;
  }
  
  .join-live-token-sec{
    font-size: 0.9em;
    background: #1361FF;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    border-radius: 0px 0px 10px 10px;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: -1px;
    font-weight: 600;
    color: var(--secondary-color);
    padding: 1em;
    text-align: center;
  }
  
  .upcoming-live-box .upcoming-live-live-status-sec{
    font-size: 0.9em;
  }
  
  .upcoming-live-box .most-popular-list-sec .live-list-count{
    width: 2.5em;
    height: 2.5em;
  }
  
  .upcoming-live-box .live-list-img{
    width: 2.5em;
    height: 2.5em;
  }
  
  .upcoming-live-box .live-list-count span{
    font-size: 0.8em;
  }
  
  .upcoming-live-box .upcoming-live-date-time-sec{
    top: 20px;
    left: 20px;
    position: absolute;
  }
  
  .upcoming-live-box .upcoming-live-date-time-sec h6{
    font-size: 1em;
    font-weight: 600;
    color: var(--secondary-color);
    margin-bottom: 0;
  }
  
  .upcoming-live-box .upcoming-live-date-time-sec p{
    margin-bottom: 0;
    font-size: 1em;
    font-weight: 500;
    color: var(--secondary-color);
  }
  .upcoming-live-img-sec .upcoming-live-img{
    width: 100%;
  }
  
  .paid-upcoming-live-footer{
    font-size: 0.9em;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
    backdrop-filter: blur(15px);
    border-radius: 0px 0px 10px 10px;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: -1px;
    font-weight: 600;
    color: var(--secondary-color);
    padding: 1em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .paid-upcoming-live-footer .paid-btn{
    background-color: #FF7801!important;
      font-size: 0.9em;
      font-weight: 600;
      color: var(--secondary-color);
      padding: 0.5em 2em;
      text-align: center;
      display: inline-block;
      border-radius: 4px;
      border-color: #FF7801;
  }
  .paid-upcoming-live-footer .paid-btn:hover{
    background-color: #FF7801!important;
    border-color: #FF7801;
  }
  
  .paid-upcoming-live-footer p{
    margin-bottom: 0;
  }

  .upcoming-live-sec{
    padding-top: 6em;
    padding-bottom: 4em;
  }

  .upcoming-live-header-sec h1{
    color: var(--primary-color);
    text-align: left;
    padding-left: 0;
    margin-bottom: 0;
    height: auto;
    padding: 1em 0;
    font-family: "Maven Pro", sans-serif !important;
    font-weight: 600;
}

.schedule-availability-video-header-sec{
    text-align: center;
}

.schedule-availability-video-header-sec h4{
    font-size: 1.5em;
    font-weight: 600;
    color: var(--tertiary-color);
    margin-top: 2em;
}

.schedule-availability-video-header-sec p{
    font-size: 1.4em;
    font-weight: 400;
    color: var(--quaternary-color);
    line-height: 1.8;
    max-width: 600px;
    margin: auto;
}

.schedule-availability-video-body-sec{
    padding: 2em 0;
}

/* .schedule-availability-calendar-box .Calendar{
    width: 100%;
} */

.time-zone-card{
    background-color: #FF7801;
    padding: 0.6em;
    font-size: 1.4em;
    color: var(--secondary-color);
    font-weight: 500;
    text-align: center;
    border-radius: 50px;
}

.video-calander-sec .Calendar{
    width: 100%;
}

.time-select-box{
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 1rem;
    padding: 1em 0;
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    padding-right: 2em;
}

.time-select-card .time-btn {
    font-size: 1.2em;
    font-weight: 700;
    padding: 10px 10px;
    border-radius: 50px;
    background-color: transparent;
    border: 1px solid #ccc!important;
    color: var(--tertiary-color);
    width: 100%
}

.time-select-card .time-btn:hover,
.time-select-card .time-btn:focus{
    background-color: transparent;
    border: 1px solid #ccc!important;
    color: var(--tertiary-color);
}

.time-select-card .time-btn.selected{
    background: linear-gradient(90deg, #2062FD 0%, #BD70EA 100%);
    color: var(--secondary-color);
}

.modal-footer-video-sec{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 3em;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 3em;
}

.modal-footer-video-sec h4{
    font-size: 1.5em;
    font-weight: 600;
    color: #FF7801;
}

.modal-footer-video-btn-sec .btn-cancel {
    font-size: 1.2em;
    font-weight: 700;
    padding: 10px 10px;
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid #ccc!important;
    color: #979797;
    width: 200px;
}

.modal-footer-video-btn-sec .btn-cancel:hover,
.modal-footer-video-btn-sec .btn-cancel:focus{
    background-color: transparent;
    border: 1px solid #ccc!important;
    color: #979797;
}

.modal-footer-video-btn-sec .btn-proceed {
    font-size: 1.2em;
    font-weight: 700;
    padding: 10px 10px;
    border-radius: 8px;
    background: linear-gradient(90deg, #2062FD 0%, #BD70EA 100%);
    border: 1px solid #ccc!important;
    color: var(--secondary-color);
    width: 200px;
}

.modal-footer-video-btn-sec .btn-proceed:hover,
.modal-footer-video-btn-sec .btn-proceed:focus{
    background-color: transparent;
    border: 1px solid #ccc!important;
    color: var(--secondary-color);
}
.modal-footer-video-btn-sec{
    display: flex;
    align-items: center;
    gap: 1em;
}

.upcoming-popular-live-sec{
    padding-top: 8em;
    padding-bottom: 4em;
}

.upcoming-popular-live-header-sec {
    display: flex;
    align-items: center;
    gap: 1em;
    padding-bottom: 3em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.upcoming-popular-live-header-sec .upcoming-go-live-btn {
    font-size: 1.3em;
    font-weight: 700;
    padding: 1em 2em;
    border-radius: 8px;
    background: linear-gradient(90deg, #2062FD 0%, #BD70EA 100%);
    border: 1px solid transparent!important;
    color: var(--secondary-color);
}

.upcoming-popular-live-header-sec .upcoming-go-live-btn:hover,
.upcoming-popular-live-header-sec .upcoming-go-live-btn:focus{
    background-color: transparent;
    border: 1px solid var(--primary-color)!important;
    color: var(--secondary-color)!important;
}

.upcoming-popular-live-header-sec .upcoming-on-live-btn {
    font-size: 1.3em;
    font-weight: 700;
    padding: 1em 2em;
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid var(--primary-color)!important;
    color: var(--primary-color);
}

.upcoming-popular-live-header-sec .upcoming-on-live-btn:hover,
.upcoming-popular-live-header-sec .upcoming-on-live-btn:focus{
    background-color: transparent;
    border: 1px solid var(--primary-color)!important;
    color: var(--primary-color);
}

.upcoming-popular-user-info-card{
    position: absolute;
    bottom: 75px;
    left: 20px;
}

.upcoming-popular-user-info{
    display: flex;
    align-items: center;
    grid-gap: 0.6em;
    gap: 0.6em;
}

.upcoming-popular-user-img{
    height: 3em;
    width: 3em;
    object-fit: cover;
    border-radius: 50%;
}

.upcoming-popular-user-details h4 {
    font-size: 1em;
    font-weight: 500;
    display: flex!important;
    align-items: center;
    grid-gap: 0.5em;
    grid-gap: 0.5em;
    gap: 0.5em;
    margin-bottom: 0;
    color: var(--secondary-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 4em;
}

.upcoming-popular-user-name{
    color: var(--secondary-color)!important;
    font-size: 0.75em;
}

.sidebar-verified-icon {
    max-width: 1em;
}

.sub-title-1{
    font-size: 1.6em;
    font-weight: 600;
    color: var(--teritary-color);
    margin-bottom: 0em;
    margin-top: 2.5em;
}

.upcoming-go-live-header-sec h4{
    font-size: 1.8em;
    font-weight: 600;
    color: var(--tertiary-color);
    margin-top: 0.2em;
}

.upcoming-go-live-body-sec{
    padding-top: 3em;
}

.toggle {
    position: absolute;
    cursor: pointer;
    opacity: 0;
  }
  
  .toggle + label {
    --toggle-color: #FFF;
    --toggle-bg-color: #D9D9D9;
    position: relative;
      padding-left: 0rem;
      line-height: 2.5rem;
      cursor: pointer;
      font-size: 1.5em;
  }
  
  /* The toggle container */
  .toggle + label:before {
      content: "";
    box-sizing: border-box;
      position: absolute;
      left: 20em;
      width: 4.5rem;
      height: 2.5rem;
      border: 1px solid var(--toggle-color);
      border-radius: 1.25rem;
      background-color: var(--toggle-bg-color);
      transition: background-color 200ms;
  }
  
  /* The toggle ball */
  .toggle + label:after {
      content: "";
    position: absolute;
      top: 0.5px;
      bottom: 0;
      left: 20.2em;
      width: 2rem;
      height: 2rem;
      margin: auto;
      transform: translate(0, 0);
      border-radius: 50%;
      background-color: var(--toggle-color);
      cursor: pointer;
      transition: transform 200ms;
  }
  
  /* The active styling */
  .toggle:checked + label {
    --toggle-color: #FFFFFF;
    --toggle-bg-color: var(--primary-color);
  }
  
  .toggle:checked + label:after {
      transform: translate(2rem, 0);
  }

  .upcoming-go-live-body-sec .time-zone-card{
    margin: 1em 0;
  }

  .time-picker-sec .rmdp-input{
    border: 1px solid #A0A4A8!important;
    padding: 1em;
    height: 45px;
    width: 100%;
}

.time-picker-sec .rmdp-input:focus{
    box-shadow: none;
    border: 0;
}

.time-picker-sec .icon-tabler-calendar-event{
  width: 20px;
  height: 20px;
  stroke-width: 1.5;
  stroke: currentcolor;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.time-picker-sec .rmdp-container {
  width: 100%;
}

/* .upcoming-go-live-form .form-label{
  font-size: 1.5em;
  color: var(--tertiary-color);
  font-weight: 600;
  margin-bottom: 0.5em;
} */

.upcoming-go-live-form .form-check-inline .form-check-input{
  width: 2em;
  height: 2em;
}

.upcoming-go-live-form label.form-check-label{
  font-size: 1.5em;
  color: #A0A4A8;
  font-weight: 500;
  margin-bottom: 0!important;
}

.upcoming-go-live-form .form-check-inline{
  gap: 0.5em;
}

.upcoming-go-live-form .form-radio-card{
  display: flex;
  align-items: center;
  gap: 3em;
}

.upcoming-go-live-form .form-control{
  background-color: #F5F7FB!important;
  border-radius: 10px!important;
  border: 0!important;
  padding: 0em 1em!important;
  height: 55px!important;
}

.upcoming-go-live-form .form-control::placeholder{
  font-size: 0.75em;
}

.upcoming-go-live-form .form-label{
  font-size: 1.5em;
  color: var(--tertiary-color);
  font-weight: 500;
  margin-bottom: 0.5em;
}

.upload-thumbanil-card{
  border: 1px dashed #979797;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;
  border-radius: 8px;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 2em;
}

.upload-thumbanil-card p{
  font-size: 1.4em;
  color: var(--teritary-color);
  margin-bottom: 0;
}

.token-display-flex{
  display: flex;
  align-items: center;
  gap: 1em;
}

.token-amount-green-bg{
  background-color: #EBFAED;
  border-radius: 5px;
  padding: 0.8em;
  color: #23882D;
  font-size: 0.8em;
}

.token-amount-display-bg-blue{
  background-color: #E8F1FF;
  border-radius: 5px;
  padding: 0.8em;
  color: #8D6CF0;
  font-size: 0.8em;
}

.strike-off{
  text-decoration: line-through;
}
